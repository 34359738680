<template>
  <v-row justify="center">
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="show"
      max-width="500px"
      max-height="800px"
      persistent
    >
      <div class="text-center" v-if="loading" style="min-height: 500px">
        <v-progress-circular
          :size="100"
          :width="10"
          style="
            text-align: center;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: 200px;
          "
          color="#7253CF"
          indeterminate
        ></v-progress-circular>
      </div>

      <v-card background-color="#f2f2f2" v-if="!loading">
        <v-card-title
          class="pb-0"
          style="background-color: #7253cf; color: white"
        >
          <div class="d-flex px-1">
            <img
              v-if="layout === 'AdminDashboardLayout'"
              src="@/assets/teachermodallogo.svg"
              height="85"
              width="100"
            />
            <img
              v-if="layout === 'CampaignDashboardLayout'"
              src="@/assets/DistrictCampaign/studentManagementModalLogo.svg"
              height="85"
              width="100"
            />
          </div>
          <div class="ml-7 mb-3 dialogTitle-text">
            {{ dialogTitle }}
          </div>
          <v-spacer></v-spacer>
          <v-icon
            text
            size="27"
            color="white"
            @click="toggleAddEditViewStudentModal({ show: false })"
            style="cursor: pointer; margin-top: -80px; margin-right: -15px"
            >mdi-close
          </v-icon>
        </v-card-title>
        <v-form ref="studentForm" lazy-validation>
          <v-card-text class="pb-0">
            <v-row no-gutters justify="center">
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Student first name"
                  outlined
                  dense
                  v-model="studentFirstName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Student last name"
                  outlined
                  dense
                  v-model="studentLastName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Parent name"
                  outlined
                  dense
                  v-model="parentName"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Parent email"
                  outlined
                  dense
                  v-model="parentEmail"
                  :rules="[rules.required, rules.email]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col class="px-3" cols="6">
                <v-text-field
                  label="Goal"
                  outlined
                  dense
                  type="number"
                  v-model="studentGoal"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                  <template v-slot:prepend-inner>
                    <v-icon color="#067605">mdi-currency-usd</v-icon>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="px-3" cols="6">
                <v-autocomplete
                  label="Select class"
                  v-model="grade"
                  :items="gradeList"
                  outlined
                  dense
                  :rules="[rules.required]"
                  :item-text="getFieldText"
                  item-value="id"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                  <template slot="item" slot-scope="data">
                    <!-- HTML that describe how select should render items when the select is open -->
                    {{ data.item.teacher_name }}-{{ data.item.grade_title }}
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters justify="center">
              <v-col class="px-3" cols="12">
                <!--<v-text-field
                  label="Jersey size"
                  outlined
                  dense
                  v-model="jerseySize"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>-->
                <v-autocomplete
                  label="Jersey size"
                  outlined
                  dense
                  v-model="jerseySize"
                  :items="jerseySizeOptions"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                ></v-autocomplete>
              </v-col>
              <!--<v-col class="px-3" cols="6">
                <v-text-field
                  label="Shorts size"
                  outlined
                  dense
                  v-model="shortSize"
                  :rules="[rules.required]"
                  class="formFields"
                  color="#7253CF"
                  :disabled="formLoading"
                  :readonly="type === 'view'"
                >
                </v-text-field>
              </v-col>-->
            </v-row>
            <v-row no-gutters justify="center">
              <v-col cols="12" class="px-3">
                <v-textarea
                  outlined
                  row-height="8"
                  color="#7253CF"
                  class="formFields"
                  :disabled="formLoading"
                  v-model="specialMessage"
                  label="Special message by student"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="pt-0 pb-7 px-7">
            <v-spacer></v-spacer>
            <v-btn
              v-if="type == 'add'"
              :loading="formLoading"
              class="rounded-lg dialogAction-btnText py-5 px-7"
              dark
              color="#38227A"
              @click="submitForm"
            >
              <span>Add Student</span>
            </v-btn>
            <v-btn
              :loading="formLoading"
              v-if="type == 'edit'"
              class="rounded-lg dialogAction-btnText py-5 px-7"
              dark
              color="#38227A"
              @click="submitForm"
            >
              <span>Save</span></v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import {
  API_ADMIN_STUDENT_MANAGEMENT_GET,
  API_ADMIN_STUDENT_MANAGEMENT_UPDATE,
  API_ADMIN_STUDENT_MANAGEMENT_POST,
  API_MASTER_GET_GRADE,
  API_MASTER_GET_MESSAGE,
} from "@/constants/APIUrls";
import RULES from "@/common/fieldRules";
import Axios from "@/api/BaseAxios";
import authToken from "@/common/authToken";
export default {
  name: "AddEditStudentModal",
  data() {
    return {
      loading: false,
      formLoading: false,
      rules: RULES,
      studentFirstName: "",
      studentLastName: "",
      parentName: "",
      parentEmail: "",
      studentGoal: null,
      grade: null,
      gradeList: [],
      jerseySize: null,
      shortSize: null,
      specialMessage: "",
      campaign_type: "",
      campaign_id: null,
      school_id: null,
      jerseySizeOptions: [
        "Extra Small",
        "Small",
        "Medium",
        "Large",
        "Extra Large",
        "Adult Small",
        "Adult Medium",
      ],
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "studentManagement/getShow",
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    layout() {
      return this.$route.meta.layout;
    },
    selectedCampaignData() {
      return this.selectedCampaign;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    show: {
      get() {
        return this.showGetter;
      },
      set(value) {
        return this.toggleAddEditViewStudentModal({ show: value });
      },
    },
    type() {
      return this.$store.state.studentManagement.addEditViewModal.type;
    },
    toastMessage() {
      if (this.type === "add") {
        return "Student Added";
      } else {
        return "Student Updated";
      }
    },
    dialogTitle() {
      switch (this.type) {
        case "add":
          return "Add new Student";
        case "edit":
          return "Edit Student";
        default:
          return "";
      }
    },
  },
  watch: {
    show(value) {
      console.log(value, "watching");
      if (value) {
        this.openCallModal();
      } else {
        this.closeCallModal();
      }
    },
  },
  methods: {
    ...mapActions({
      // student Modal
      toggleAddEditViewStudentModal: "studentManagement/toggleModal",
      showToast: "snackBar/showToast",
    }),
    getFieldText(item) {
      return `${item.teacher_name} - ${item.grade_title}`;
    },
    openCallModal() {
      console.log("open call method");
      this.getGrade();
      if (this.type === "add") {
        this.studentGoal =
          this.selectedCampaignData.campaignData.student_default_goal;
      }
      console.log(this.selectedCampaignData);
    },
    closeCallModal() {
      console.log("close call method");
      this.studentFirstName = "";
      this.studentLastName = "";
      this.parentName = "";
      this.parentEmail = "";
      this.studentGoal = null;
      this.grade = null;
      this.gradeList = [];
      this.jerseySize = null;
      this.shortSize = null;
      this.specialMessage = null;
      this.campaign_type = "";
      this.campaign_id = null;
      this.school_id = null;
    },
    getGrade() {
      const self = this;

      self.loading = true;

      const successHandler = (res) => {
        const data = res.data;
        console.log(data, "grades");
        self.gradeList = data.grade_list;
        self.loading = false;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
      };
      const finallyHandler = () => {
        if (this.type === "edit") {
          this.getStudentDetail();
        } else {
          this.getSpecialMessage();
        }
      };
      const formJson = {};
      let PANEL = this.$route.matched[0].path.substring(1);
      if (this.layout === "AdminDashboardLayout") {
        formJson["student_id"] =
          this.$store.state.studentManagement.addEditViewModal.studentID;
      } else {
        if (PANEL === "district") {
          formJson["student_id"] =
            this.$store.state.studentManagement.addEditViewModal.studentID;
        } else if (PANEL === "school") {
          formJson["campaign_id"] = this.selectedCampaignData.campaignData.id;
          formJson["school_id"] = this.selectedCampaignData.organizationData.id;
        } else {
          //teacher panel student management handling block
          formJson["campaign_id"] = this.selectedCampaignData.campaignData.id;
          formJson["teacher_id"] =
            this.selectedCampaignData.organizationData.id;
          this.grade = this.selectedCampaignData.organizationData.id;
        }
      }
      console.log(formJson, "grade form");
      Axios.request_GET(
        API_MASTER_GET_GRADE,
        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        false,
        finallyHandler
      );
    },
    getSpecialMessage() {
      const successHandler = (res) => {
        console.log(res.data.special_message_list[0].message, "specialMessage");
        if (res.data.special_message_list[0]) {
          this.specialMessage = res.data.special_message_list[0].message;
          this.loading = false;
        }
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["message_for"] = "student";
      formData["message_type"] = "special_message";
      formData["campaign"] = this.selectedCampaignData.campaignData.id;
      formData["school"] = this.selectedCampaignData.organizationData.id;
      Axios.request_GET(
        API_MASTER_GET_MESSAGE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    getStudentDetail() {
      const self = this;
      self.loading = true;

      const successHandler = (res) => {
        const data = res.data;
        console.log(data);
        self.studentFirstName = data.student_detail.student_first_name;
        self.studentLastName = data.student_detail.student_last_name;
        self.parentName = data.student_detail.parent_name;
        self.parentEmail = data.student_detail.parent_email;
        self.studentGoal = data.student_detail.student_goal;
        self.grade = data.student_detail.teacher;
        self.jerseySize = data.student_detail.jersey_size;
        // self.shortSize = data.student_detail.shorts_size;
        self.specialMessage = data.student_detail.student_special_message;
        self.loading = false;
        this.campaign_type = data.student_detail.campaign_type;
      };
      const failureHandler = (res) => {
        const data = res.data;
        console.log(data);
        self.loading = false;
      };

      let formData = {};
      formData["student_id"] =
        this.$store.state.studentManagement.addEditViewModal.studentID;

      console.log(formData);
      Axios.request_GET(
        API_ADMIN_STUDENT_MANAGEMENT_GET,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true
      );
    },
    submitForm() {
      if (this.$refs.studentForm.validate()) {
        const self = this;
        self.formLoading = true;

        const successHandler = (res) => {
          const data = res.data;
          console.log(data);
          self.formLoading = false;
          this.toggleAddEditViewStudentModal({ show: false });
          this.$emit("reload");
          this.showToast({
            message: this.toastMessage,
            color: "s",
          });
        };
        const failureHandler = (res) => {
          const data = res.data;
          console.log(data);
          self.formLoading = false;
          this.showToast({
            message: res,
            color: "e",
          });
        };
        let formData = {};
        formData["student_first_name"] = this.studentFirstName;
        formData["student_last_name"] = this.studentLastName;
        formData["parent_name"] = this.parentName;
        formData["parent_email"] = this.parentEmail;
        formData["student_goal"] = this.studentGoal;
        formData["teacher"] = this.grade;
        formData["jersey_size"] = this.jerseySize;
        // formData["shorts_size"] = this.shortSize;
        formData["student_special_message"] = this.specialMessage;

        if (this.type === "edit") {
          formData["student_id"] =
            this.$store.state.studentManagement.addEditViewModal.studentID;
        } else {
          if (this.$route.matched[0].path.substring(1) === "teacher") {
            formData["campaign"] = this.selectedCampaignData.campaignData.id;
          } else {
            formData["campaign"] = this.selectedCampaignData.campaignData.id;
            formData["school"] = this.selectedCampaignData.organizationData.id;
          }
        }
        console.log(formData, "submit form");
        if (this.type === "edit") {
          /**
           * API Call for UPDATE Student
           */
          Axios.request_PATCH(
            API_ADMIN_STUDENT_MANAGEMENT_UPDATE,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
        if (this.type === "add") {
          /**
           * API Call for POST Student
           */
          Axios.request_POST(
            API_ADMIN_STUDENT_MANAGEMENT_POST,
            formData,
            {},
            successHandler,
            failureHandler,
            false
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.dialogTitle-text {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 315%;
  color: #ffffff;
}

.dialogAction-btnText >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 161.5%;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: none !important;
}
.formFields {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  /* identical to box height */

  color: #757575;
}
</style>
